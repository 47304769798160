/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./Footer";
import "./layout.css";

export const PureLayout = ({ data, children }) => {
  return (
    <>
      <a className="skip-link" href="#main-content">
        Skip to content
      </a>
      <Header
        siteTitle={data.site.siteMetadata.title}
      />
      <main id="main-content">
        <div className="container--main flow">{children}</div>
      </main>
      <Footer
        secondaryLinks={data.site.siteMetadata.secondaryLinks}
        siteTitle={data.site.siteMetadata.title}
      />
    </>
  );
};

PureLayout.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          secondaryLinks {
            name
            link
          }
        }
      }
    }
  `);

  return <PureLayout {...props} data={data} />;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
