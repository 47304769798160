import React, { Component } from "react";
import * as headerStyles from "./header.module.css";
import * as hamburgerStyles from "./hamburger.module.css";
import { Link } from "gatsby";
import Logo from "../images/logo.inline.svg";

class Header extends Component {
  state = { showMenu: false };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  render() {
    const burgerActive = this.state.showMenu ? "is-active" : "";
    const burgerAria = this.state.showMenu ? true : false;

    return (
      <header className={`header--global ${headerStyles.header}`}>
        <figure className={`logo--global ${headerStyles.logoGlobal}`}>
          <Link to="/" aria-label="Agoro Carbon Solutions - Home">
            <Logo width={125} />
          </Link>
        </figure>
        <button
          className={`hamburger hide-for-lg ${hamburgerStyles.hamburger} ${burgerActive}`}
          onClick={this.toggleMenu}
          aria-label="toggle menu"
          aria-expanded={`${burgerAria}`}
          id="hamburger"
        >
          <div className={`hamburger-box ${hamburgerStyles.hamburgerBox}`}>
            <div
              className={`hamburger-inner ${hamburgerStyles.hamburgerInner}`}
            ></div>
          </div>
        </button>
        <nav
          className={`nav--global ${headerStyles.navGlobal} ${burgerActive}`}
        >
          <ul role="list">
            <li className={`nav--item`}>
              <Link to="/">Home</Link>
            </li>
            <li className={`nav--item`}>
              <Link to="/farmers-advisors/">Farmers/Advisors</Link>
              <ul className={`sub--nav`}>
                <li>
                  <Link to="https://us.agorocarbonalliance.com/farmers-advisors/">U.S. Farmers</Link>
                </li>
              </ul>
            </li>
            <li className={`nav--item`}>
              <Link to="/partners-supporters/">Partners/Supporters</Link>
            </li>
            <li className={`nav--item`}>
              <Link to="/about-us/">About Us</Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

// export the class so you can call it elsewhere
export default Header;
