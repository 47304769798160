import * as React from "react";
import PropTypes from "prop-types";
import * as footerStyles from "./footer.module.css";
import { Link } from "gatsby";
import Logo from "../images/logo.inline.svg";
import LogoYara from "../images/logo-yara.inline.svg";
import Facebook from "../images/icon.facebook.inline.svg";
import Instagram from "../images/icon.instagram.inline.svg";
import Twitter from "../images/icon.twitter.inline.svg";
import YouTube from "../images/icon.youtube.inline.svg";
import LinkedIn from "../images/icon.linkedin.inline.svg";

const Footer = ({ siteTitle, secondaryLinks }) => (
  <footer className={`footer--global ${footerStyles.footerGlobal}`}>
    <div className={`container--main ${footerStyles.containerMain}`}>
      <figure className={`logo--footer ${footerStyles.logoFooter}`}>
        <Link to="/" aria-label={siteTitle + " - Home"}>
          <Logo width={116} />
        </Link>
        <a href="https://www.yara.com">
          <LogoYara width={35} />
          <span className="sr-only">Yara International</span>
        </a>
      </figure>
      <nav>
        <ul
          role="list"
          className={`nav--social-media ${footerStyles.navSocialMedia}`}
        >
          <li>
            <a href="https://facebook.com/AgoroCarbonAlliance">
              <Facebook height={26} />
              <span className="sr-only">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/AgoroCarbonAlliance">
              <Instagram height={26} />
              <span className="sr-only">Instagram</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AgoroCarbon">
              <Twitter height={26} />
              <span className="sr-only">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://youtube.com/channel/UCsQIt5Kx_UrdBa_f44xfDHA">
              <YouTube height={26} />
              <span className="sr-only">YouTube</span>
            </a>
          </li>
          <li>
            <a href="https://linkedin.com/company/agoro-carbon-alliance/">
              <LinkedIn height={26} />
              <span className="sr-only">LinkedIn</span>
            </a>
          </li>
        </ul>
        <ul role="list" className={`nav--footer ${footerStyles.navFooter}`}>
          {secondaryLinks.map((link) => (
            <li key={link.name}>
              <a href={link.link}>{link.name}</a>
            </li>
          ))}
          <li>
            &copy;{new Date().getFullYear()} {siteTitle}
          </li>
        </ul>
      </nav>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
